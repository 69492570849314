import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "AllianzUploads _max940"
  }, [_c('div', {
    staticClass: "card"
  }, [_vm._m(0), _c('div', {
    staticClass: "card-content"
  }, [_c('p', [_vm._v("Diese Liste zeigt an, wann zuletzt Dateien zu den Akten hinzugefügt worden sind.")]), _c('p', [_c('label', [_vm._v("Anzahl Tage zurück: "), _c('br'), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.number",
      value: _vm.dayCount,
      expression: "dayCount",
      modifiers: {
        "number": true
      }
    }],
    attrs: {
      "type": "number",
      "placeholder": "Anzahl Tage zurück"
    },
    domProps: {
      "value": _vm.dayCount
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.dayCount = _vm._n($event.target.value);
      },
      "blur": function blur($event) {
        return _vm.$forceUpdate();
      }
    }
  }), _c('div', {
    staticClass: "btn _green",
    on: {
      "click": _vm.listAllianzFiles
    }
  }, [_vm._v("Daten anfordern")])])]), _c('p', [_c('v-checkbox', {
    attrs: {
      "rctype": "radio",
      "item": "list",
      "label": "Übersichts-Liste"
    },
    model: {
      value: _vm.listView,
      callback: function callback($$v) {
        _vm.listView = $$v;
      },
      expression: "listView"
    }
  }), _c('br'), _c('v-checkbox', {
    attrs: {
      "rctype": "radio",
      "item": "table",
      "label": "nach Datum sortierte Liste"
    },
    model: {
      value: _vm.listView,
      callback: function callback($$v) {
        _vm.listView = $$v;
      },
      expression: "listView"
    }
  })], 1), _c('p', [_c('v-checkbox', {
    attrs: {
      "item": "Bilder",
      "label": "Bilder"
    },
    model: {
      value: _vm.whichfiles,
      callback: function callback($$v) {
        _vm.whichfiles = $$v;
      },
      expression: "whichfiles"
    }
  }), _c('br'), _c('v-checkbox', {
    attrs: {
      "item": "Dokumente",
      "label": "Dokumente"
    },
    model: {
      value: _vm.whichfiles,
      callback: function callback($$v) {
        _vm.whichfiles = $$v;
      },
      expression: "whichfiles"
    }
  }), _c('br'), _c('v-checkbox', {
    attrs: {
      "item": "HVW",
      "label": "HVW"
    },
    model: {
      value: _vm.whichfiles,
      callback: function callback($$v) {
        _vm.whichfiles = $$v;
      },
      expression: "whichfiles"
    }
  })], 1)])]), _c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "card-header"
  }, [_c('h4', [_vm._v("Letzte Uploads seit " + _vm._s(_vm.dayCount) + " Tagen:")])]), _c('div', {
    staticClass: "card-content"
  }, [_vm.listView == 'list' ? _c('ul', {
    staticClass: "padding"
  }, _vm._l(_vm.tree, function (fi, idx) {
    return _c('li', [_vm.hasfile(idx) && (_vm._fbProjects[idx] || {}).akt ? _c('div', {
      staticClass: "pad"
    }, [_c('div', {
      staticClass: "fl"
    }, [_c('div', {
      staticClass: "bold"
    }, [_vm._v(_vm._s(_vm._fbProjects[idx].allianzData.adresse_risiko) + ", " + _vm._s(_vm._fbProjects[idx].allianzData.plz_risiko) + " " + _vm._s(_vm._fbProjects[idx].allianzData.ortschaft_risiko) + " ")])]), _c('div', {
      staticClass: "fr"
    }, [_c('router-link', {
      staticClass: "link bold",
      attrs: {
        "to": "/allianz/" + idx
      }
    }, [_vm._v(_vm._s(_vm._fbProjects[idx].allianzData.polnr))])], 1), _c('div', {
      staticClass: "clear"
    }), _c('hr')]) : _vm.hasfile(idx) && _vm._fbHausverwaltungen[idx] ? _c('div', {
      staticClass: "pad"
    }, [_c('div', {
      staticClass: "fl"
    }, [_c('span', {
      staticClass: "bold _redp"
    }, [_vm._v("HV: ")]), _c('span', [_vm._v(" " + _vm._s(_vm._fbHausverwaltungen[idx].name))])]), _c('div', {
      staticClass: "fr"
    }, [_c('router-link', {
      staticClass: "link bold",
      attrs: {
        "to": "/allianz/hvw/" + idx
      }
    }, [_vm._v(_vm._s(_vm._fbHausverwaltungen[idx].name))])], 1)]) : _vm._e(), _vm.hasfile(idx) && (_vm._fbProjects[idx] || _vm._fbHausverwaltungen[idx]) ? _c('span', _vm._l(_vm.whichfiles, function (lt) {
      return _c('span', [fi[lt] ? _c('div', {
        staticClass: "paddleft"
      }, [_c('div', {
        staticClass: "padding"
      }, [_c('h5', [_vm._v(_vm._s(lt))]), _vm._l(fi[lt], function (fi2) {
        return _c('div', {
          staticClass: "pad"
        }, [_c('div', {
          staticClass: "fl"
        }, [_c('a', {
          staticClass: "link bold",
          attrs: {
            "href": _vm.$env.mainurl + fi2.path + _vm.tok,
            "target": "_blank"
          }
        }, [_vm._v(_vm._s(fi2.name) + " ")])]), _c('div', {
          staticClass: "fr"
        }, [_vm._v(_vm._s(_vm.$dayjs(fi2.mtimeMs).format('DD. MMM. YYYY HH.mm')))]), _c('div', {
          staticClass: "clear"
        })]);
      })], 2)]) : _vm._e()]);
    }), 0) : _vm._e(), _c('div', {
      staticClass: "clear"
    })]);
  }), 0) : _vm._e(), _vm.listView == 'table' ? _c('ul', {
    staticClass: "padding"
  }, _vm._l(_vm.sortedTree, function (fi, idx) {
    return _vm.whichfiles.includes(fi.doc) ? _c('li', [(_vm._fbProjects[fi.id] || {}).allianzData ? _c('div', {
      staticClass: "pad"
    }, [_c('span', {
      staticClass: "bold _greenp"
    }, [_vm._v("AKT: ")]), _c('router-link', {
      staticClass: "link bold",
      attrs: {
        "to": "/allianz/list/" + fi.id
      }
    }, [_vm._v(_vm._s(_vm._fbProjects[fi.id].allianzData.polnr) + " ")]), _c('span', [_vm._v(" - ")]), _c('a', {
      staticClass: "link bold",
      attrs: {
        "href": _vm.$env.mainurl + fi.path + _vm.tok,
        "target": "_blank"
      }
    }, [_vm._v(_vm._s(fi.name) + " ")]), _c('span', [_vm._v("(" + _vm._s(fi.doc) + ")")]), _c('div', {
      staticClass: "fr"
    }, [_vm._v(_vm._s(_vm.$dayjs(fi.mtimeMs).format('DD. MMM. YYYY HH.mm')))]), _c('div', {
      staticClass: "clear"
    })], 1) : _vm._e(), (_vm._fbHausverwaltungen[fi.id] || {}).name ? _c('div', {
      staticClass: "pad"
    }, [_c('span', {
      staticClass: "bold _redp"
    }, [_vm._v("HV: ")]), _c('router-link', {
      staticClass: "link bold",
      attrs: {
        "to": "/allianz/hvw/" + fi.id
      }
    }, [_vm._v(_vm._s((_vm._fbHausverwaltungen[fi.id] || {}).name || fi.id) + " ")]), _c('span', [_vm._v(" - ")]), _c('a', {
      staticClass: "link bold",
      attrs: {
        "href": _vm.$env.mainurl + fi.path + _vm.tok,
        "target": "_blank"
      }
    }, [_vm._v(_vm._s(fi.name) + " ")]), _c('span', [_vm._v("(" + _vm._s(fi.doc) + ")")]), _c('div', {
      staticClass: "fr"
    }, [_vm._v(_vm._s(_vm.$dayjs(fi.mtimeMs).format('DD. MMM. YYYY HH.mm')))]), _c('div', {
      staticClass: "clear"
    })], 1) : _vm._e()]) : _vm._e();
  }), 0) : _vm._e()])])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "card-header"
  }, [_c('h4', [_vm._v("Allianz Uploads")])]);
}];
export { render, staticRenderFns };